import * as React from "react"
import * as styles from './WideImageSection.module.scss'
import '../../styles/columnBreakpoints.scss';
import BackgroundImage from 'gatsby-background-image';
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';

const WideImageSection = ({image, heading, content, contentClass, children, blank}) => {
  const cClass=styles[contentClass];
  if(!image) return <></>;
  return (
    <div className={styles.container}>
      <BackgroundImage
        Tag="section"
        className="column"
        fluid={image.childImageSharp ? image.childImageSharp.fluid : null}>
          {heading ? <h2>{heading}</h2> : null}
          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={content} linkTarget={blank ? '_blank' : ''} className={cClass}/>
          {children}
      </BackgroundImage>
    </div>
  )
}
export default WideImageSection;